import React from "react"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import Layout from "../layouts/layout"

const IndexPage = ({ data }) => {
    data.hero.buttons = (
        <div className="mt-8">
            <Link
                to="https://player.vimeo.com/external/516661954.hd.mp4?s=0f7b319e4c7e29a8a9b9873ec7688d412beccfc8&profile_id=174"
                target="_blank"
            >
                <button className="bg-transparent hover:bg-mane-yellow text-white text-xs font-semibold font-aktivcd hover:text-gray-800 uppercase py-3 px-8 border border-white hover:border-transparent tracking-tight mr-6">
                    watch video
                </button>
            </Link>
        </div>
    )

    return (
        <Layout hero={{ home: true, ...data.hero }}>
            <div className="container mx-auto px-4 mb-16">
                <div className="md:flex items-center justify-between">
                    <div className="flex-1 mb-4 md:mb-0">
                        <Img
                            className="sm:ml-auto"
                            alt="promo image"
                            fluid={data.contentfulHomepage.promoImage.fluid}
                        />
                    </div>
                    <div className="flex-1 mr-6 order-first">
                        <div className="mb-6">
                            <h2 className="promo-heading">
                                {data.contentfulHomepage.promoHeadingOne}
                            </h2>
                            <h2 className="promo-heading">
                                {data.contentfulHomepage.promoHeadingTwo}
                            </h2>
                        </div>
                        <p className="soft-gray leading-relaxed">
                            {data.contentfulHomepage.promoText}
                        </p>
                        <div className="inline-block uppercase pt-6 border-b-2 text-xs pb-1 border-mane-yellow text-gray-800 font-bold font-aktivcd">
                            <Link to="/who-we-are">
                                {data.contentfulHomepage.promoLink}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* Solutions & Services */}
            <div
                className="mb-16 relative overflow-hidden sm:py-20 bg-center bg-cover bg-no-repeat"
                style={{
                    backgroundImage: `url(${data.contentfulHomepage.promoTwoBackgroundImage.fluid.srcWebp})`,
                }}
            >
                <div className="container mx-auto">
                    <div
                        className="flex sm:w-1/2 p-4 sm:p-8 py-8 sm:-ml-4"
                        style={{ backgroundColor: "rgba(10, 30, 49, 0.9)" }}
                    >
                        <div className="flex flex-col">
                            <div className="sm:w-3/5">
                                <h2 className="text-3xl sm:text-5xl font-bold text-white leading-tight">
                                    <span className="text-blue-200 block">
                                        {
                                            data.contentfulHomepage
                                                .promoTwoHeadingOne
                                        }
                                    </span>
                                    {data.contentfulHomepage.promoTwoHeadingTwo}
                                </h2>
                            </div>
                            <p className="text-white">
                                {data.contentfulHomepage.promoTwoText}
                            </p>
                            <div
                                style={{ width: "max-content" }}
                                className="inline-block uppercase pt-6 pb-1 border-b-2 text-xs border-mane-yellow text-white font-bold font-aktivcd"
                            >
                                <Link to="/solutions-services">
                                    {data.contentfulHomepage.promoTwoLink}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Our Work */}
            <div className="container mx-auto px-4 mb-16">
                <div className="sm:flex justify-between">
                    <div className="flex-1 sm:w-1/2 order-first pr-4 mb-6">
                        <div className="mb-6">
                            <h2 className="text-3xl sm:text-5xl font-bold dark-blue leading-tight">
                                {data.contentfulHomepage.ourWorkHeading}
                            </h2>
                        </div>
                        <p className="soft-gray sm:mb-4 md:mb-0">
                            {data.contentfulHomepage.ourWorkText}
                        </p>
                        <div
                            style={{ width: "max-content" }}
                            className="inline-block uppercase pt-6 pb-1 border-b-2 text-xs border-mane-yellow text-gray-800 font-bold font-aktivcd"
                        >
                            <Link to="/our-work">
                                {data.contentfulHomepage.ourWorkLink}
                            </Link>
                        </div>
                    </div>

                    {data.contentfulHomepage.projects.map(project => (
                        <div
                            className="flex flex-col flex-1 mb-4 sm:mb-0 sm:w-1/2 border-shadow sm:ml-4"
                            key={project.id}
                        >
                            <Link
                                to={project.slug && `/our-work/${project.slug}`}
                            >
                                <Img
                                    className="sm:h-40 lg:h-64"
                                    style={{ maxHeight: "265px" }}
                                    alt={project.picture.title}
                                    fluid={project.picture.fluid}
                                />
                                <div className="mb-4 p-4">
                                    <h3 className="text-gray-800 text-xl font-bold">
                                        {project.name}
                                    </h3>
                                    <h4 className="uppercase text-xs dark-blue font-aktivcd font-bold mb-4">
                                        {project.location}
                                    </h4>
                                    <p className="text-sm">
                                        {
                                            project.description
                                                .childMarkdownRemark.excerpt
                                        }
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            {/* Recent News */}
            <div className="container px-4 mx-auto mb-8">
                <div className="mb-6">
                    <h2 className="text-3xl sm:text-5xl font-bold dark-blue leading-tight">
                        Recent News &amp; Updates
                    </h2>
                </div>

                <div className="sm:flex flex-wrap mx-auto">
                    {/* large featured card */}
                    <div className="sm:flex flex-col sm:w-1/2 border-shadow pb-4 mb-4">
                        <div className="mb-4">
                            <div className="mb-4">
                                <Link
                                    to={`/news/${data.contentfulHomepage.featurePerspective.slug}`}
                                >
                                    <Img
                                        className="object-cover object-center h-full w-full lg:w-auto block"
                                        alt=""
                                        fluid={
                                            data.contentfulHomepage
                                                .featurePerspective.picture &&
                                            data.contentfulHomepage
                                                .featurePerspective.picture
                                                .fluid
                                        }
                                    />
                                </Link>
                            </div>
                            <div className="px-4">
                                <div className="inline md:inline-block uppercase text-xs dark-blue font-bold font-aktivcd">
                                    Feature Perspective
                                </div>
                                <span className="square"></span>
                                <span className="text-xs">
                                    {
                                        data.contentfulHomepage
                                            .featurePerspective.date
                                    }
                                </span>
                            </div>
                        </div>

                        <div className="px-4 mb-4">
                            <div className="mb-4">
                              <h3 className="font-bold text-gray-800 text-xl">
                                  {
                                      data.contentfulHomepage.featurePerspective
                                          .title
                                  }
                              </h3>
                              <h5 className="text-xs -mt-1">{ data.contentfulHomepage.featurePerspective.location }</h5>
                            </div>
                            <p>
                                {
                                    data.contentfulHomepage.featurePerspective
                                        .description
                                }
                            </p>
                            <Link
                                className="inline-block uppercase pt-6 border-b-2 pb-1 text-xs border-mane-yellow text-gray-800 font-bold"
                                to={`/news/${data.contentfulHomepage.featurePerspective.slug}`}
                            >
                                Read More
                            </Link>
                        </div>
                    </div>

                    {/* sidebar */}
                    <div className="sm:flex flex-col sm:w-1/2 justify-between">
                        {data.contentfulHomepage.recentNewsAndUpdates.map(
                            item => (
                                <div
                                    className="lg:flex flex-wrap sm:pl-6 sm:ml-2 mb-4"
                                    key={item.id}
                                >
                                    <div className="lg:w-1/3">
                                        <Link
                                            to={
                                                item.__typename ===
                                                "ContentfulNews"
                                                    ? `/news/${item.slug}`
                                                    : `/our-work/${item.slug}`
                                            }
                                        >
                                            <Img
                                                className="lg:mr-4 object-cover object-center h-64 sm:h-32 w-full lg:w-auto block"
                                                alt=""
                                                fluid={
                                                    item.picture &&
                                                    item.picture.fluid
                                                }
                                            />
                                        </Link>
                                    </div>
                                    <div className="flex-col lg:w-2/3">
                                        <div className="inline md:inline-block uppercase text-xs dark-blue font-bold font-aktivcd">
                                            {item.__typename ===
                                            "ContentfulNews" ? (
                                                <Link to={`/news/${item.slug}`}>
                                                    Company News
                                                </Link>
                                            ) : (
                                                <Link
                                                    to={`/our-work/${item.slug}`}
                                                >
                                                    Project
                                                </Link>
                                            )}
                                        </div>
                                        <span className="square"></span>
                                        <span className="text-xs">
                                            {item.date}
                                        </span>
                                        <Link
                                            to={
                                                item.__typename ===
                                                "ContentfulNews"
                                                    ? `/news/${item.slug}`
                                                    : `/our-work/${item.slug}`
                                            }
                                        >
                                            <h4 className="font-bold">
                                                {item.title}
                                            </h4>
                                            <span className="block text-xs -mt-1">
                                              {item.location}
                                            </span>
                                        </Link>
                                        <p className="hidden xl:block text-sm mt-3">
                                            {item.description ||
                                                item.innerDescrption
                                                    .childMarkdownRemark
                                                    .excerpt ||
                                                ""}
                                        </p>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="text-center">
                    <Link
                        to="/news"
                        className="inline-block uppercase pt-6 border-b-2 pb-1 text-xs border-mane-yellow text-gray-800 font-bold"
                    >
                        View All
                    </Link>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
    query homePage {
        hero: contentfulHomepage {
            heroHeading {
                childMarkdownRemark {
                    html
                }
            }
            heroText {
                childMarkdownRemark {
                    html
                }
            }
            heroBackgroundImage {
                resize(width: 1440, quality: 70) {
                    src
                }
            }
            backgroundVideo
        }
        contentfulHomepage {
            promoHeadingOne
            promoHeadingTwo
            promoText
            promoLink
            promoImage {
                fluid(maxWidth: 570) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                }
                file {
                    url
                    fileName
                    contentType
                }
            }
            promoTwoHeadingOne
            promoTwoHeadingTwo
            promoTwoText
            promoTwoLink
            promoTwoBackgroundImage {
                fluid(maxWidth: 1400) {
                    ...GatsbyContentfulFluid_withWebp_noBase64
                    srcWebp
                }
                file {
                    url
                    fileName
                    contentType
                }
            }
            ourWorkHeading
            ourWorkText
            ourWorkLink
            featurePerspective {
                title
                slug
                description
                date(formatString: "MMMM DD, YYYY")
                location
                picture {
                    title
                    fluid(maxHeight: 600, quality: 60) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            projects {
                id
                name
                slug
                description {
                    childMarkdownRemark {
                        excerpt
                    }
                }
                picture {
                    title
                    fluid(maxHeight: 600, quality: 60) {
                        ...GatsbyContentfulFluid_withWebp_noBase64
                    }
                }
            }
            recentNewsAndUpdates {
                ... on ContentfulNews {
                    __typename
                    id
                    title
                    slug
                    description
                    date(formatString: "MMMM, YYYY")
                    location
                    picture {
                        fluid(maxWidth: 200, quality: 50) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
                ... on ContentfulProjects {
                    __typename
                    id
                    title: name
                    slug
                    date: createdAt(formatString: "MMMM, YYYY")
                    innerDescrption {
                        childMarkdownRemark {
                            excerpt(pruneLength: 80)
                        }
                    }
                    picture {
                        title
                        fluid(maxWidth: 200, quality: 50) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
